<style scoped>
	.menu-100 {
		max-height: calc(100% - 24px) !important;
	}
</style>

<template>
	<div>
		<div 
		  class="mb-10"
		  :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
		>
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.signup.request.title', { brand: $whitelabel.getClient().name }) }}
			</h1>
		</div>

		<v-form @submit.prevent="next" autocomplete="off">
			<v-container class="pa-0">
				<v-text-field
				  dense
				  ref="email"
				  v-model.trim="email"
				  class="mb-2"
				  :label="$vuetify.lang.t('$vuetify.signup.request.form.email.label')"
				  name="email"
				  type="email"
				  hide-details="auto"
				  outlined
				  :error-messages="emailError"
				  :loading="loading.email"
				  :readonly="loading.email"
				  :disabled="disabled.email"
				  @input="$v.email.$touch()"
				  @change="checkEmail"
				  :hint="(!disabled.email ? $vuetify.lang.t('$vuetify.signup.request.form.email.hint') : $vuetify.lang.t('$vuetify.signup.request.form.email.verify'))"
				  persistent-hint
				  autocomplete="off"
				/>

				<v-row no-gutters>
					<v-col
					  cols="12"
					  md="6"
					>
						<v-text-field
						  dense
						  ref="firstname"
						  v-model.trim="firstname"
						  class="mb-2"
						  :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
						  :label="$vuetify.lang.t('$vuetify.signup.request.form.firstname.label')"
						  name="firstname"
						  type="text"
						  hide-details="auto"
						  outlined
						  :error-messages="firstnameError"
						  @input="$v.firstname.$touch()"
						  autocomplete="off"
						/>
					</v-col>
					<v-col
					  cols="12"
					  md="6"
					>
						<v-text-field
						  dense
						  ref="lastname"
						  v-model.trim="lastname"
						  class="mb-2"
						  :class="{ 'ml-1': $vuetify.breakpoint.mdAndUp }"
						  :label="$vuetify.lang.t('$vuetify.signup.request.form.lastname.label')"
						  name="lastname"
						  type="text"
						  hide-details="auto"
						  outlined
						  :error-messages="lastnameError"
						  @input="$v.lastname.$touch()"
						  autocomplete="off"
						/>
					</v-col>
				</v-row>

				<v-text-field
				  dense
				  ref="username"
				  v-model.trim="username"
				  class="mb-2"
				  :label="$vuetify.lang.t('$vuetify.signup.request.form.username.label')"
				  name="username"
				  type="text"
				  hide-details="auto"
				  outlined
				  :error-messages="usernameError"
				  :loading="loading.username"
				  :readonly="loading.username"
				  @input="$v.username.$touch()"
				  @change="checkUsername"
				  :hint="$vuetify.lang.t('$vuetify.signup.request.form.username.hint')"
				  persistent-hint
				  autocomplete="nope"
				/>

				<v-row no-gutters>
					<v-menu
					  auto
					  absolute
					  top
					  bottom
					  content-class="menu-100"
					  allow-overflow
			          origin="center left"
			          transition="scale-transition"
			        >
			            <template v-slot:activator="{ on, attrs }">
			                <v-btn
					          class="mr-2"
			                  style="height: 40px;flex:0;"
			                  text
			                  v-bind="attrs"
			                  v-on="on"
			                >
			                    {{ country.phone[country.selected].id }}
			                    <v-icon color="secondary" right>
									mdi-chevron-down
								</v-icon>
			                </v-btn>
			            </template>

			            <v-list 
			              dense
			            >
			            	<v-list-item-group
					          v-model="country.selected"
					          mandatory
					      	>
				                <v-list-item 
				                  dense
				                  v-for="(item, index) in country.phone"
				                  :key="index"
						          @click="checkPhone"
				                >
			                    	<v-list-item-title>{{ item.text }} +{{ item.code }}</v-list-item-title>
				                </v-list-item>
				            </v-list-item-group>
			            </v-list>
			        </v-menu>
					<v-text-field
					  dense
					  style="flex:1;"
					  ref="phone"
					  v-model.trim="phone"
					  class="mb-2"
					  :prefix="'+' + country.phone[country.selected].code"
					  :label="$vuetify.lang.t('$vuetify.signup.request.form.phone.label')"
					  name="phone"
					  type="text"
				  	  :loading="loading.phone"
				  	  :readonly="loading.phone"
					  hide-details="auto"
					  outlined
					  :error-messages="phoneError"
					  @input="$v.phone.$touch()"
				      @change="checkPhone"
					  autocomplete="off"
					/>
				</v-row>

				<v-text-field
				  dense
				  ref="referral"
				  v-model.trim="referral"
				  class="mb-2"
				  :label="$vuetify.lang.t('$vuetify.signup.request.form.referral.label')"
				  name="referral"
				  type="text"
				  hide-details="auto"
				  outlined
				  :error-messages="referralError"
				  :loading="loading.referral"
				  :readonly="loading.referral"
				  @input="$v.referral.$touch()"
				  @change="checkReferral"
				  autocomplete="off"
				/>

				<v-row no-gutters>
					<v-col
					  cols="12"
					  md="6"
					>
						<v-text-field
						  dense
						  ref="password"
						  v-model.trim="password"
						  class="mb-2"
						  :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
						  :label="$vuetify.lang.t('$vuetify.signup.request.form.password.label')"
						  name="password"
						  :append-icon="show.password ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
						  :type="show.password ? 'text' : 'password'"
						  hide-details="auto"
						  outlined
						  :error-messages="passwordError"
						  @input="$v.password.$touch()"
						  @click:append="show.password = !show.password"
						  :hint="$vuetify.lang.t('$vuetify.signup.request.form.password.hint')"
						  persistent-hint
						  autocomplete="new-password"
						/>
					</v-col>
					<v-col
					  cols="12"
					  md="6"
					  class="mb-2"
					>
						<v-text-field
						  dense
						  ref="passconf"
						  v-model.trim="passconf"
						  class="mb-0"
						  :class="{ 'ml-1': $vuetify.breakpoint.mdAndUp }"
						  :label="$vuetify.lang.t('$vuetify.signup.request.form.passconf.label')"
						  name="passconf"
						  :append-icon="show.passconf ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
						  :type="show.passconf ? 'text' : 'password'"
						  hide-details="auto"
						  outlined
						  :error-messages="passconfError"
						  @input="$v.passconf.$touch()"
						  @click:append="show.passconf = !show.passconf"
						  autocomplete="off"
						/>
					</v-col>
				</v-row>

				<v-checkbox ref="terms" v-model="terms" name="terms" dense hide-details="auto" :error-messages="termsError" @change="$v.terms.$touch()">
					<template v-slot:label>
						<div>
							I accept the 
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a
										target="_blank"
										href="https://botika.online/terms-and-conditions-2/index.php"
										@click.stop
										v-on="on"
									>
									Terms
									</a>
								</template>
								Opens in new window
							</v-tooltip>
							and
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a
										target="_blank"
										href="https://botika.online/botika-privacy-policy/index.php"
										@click.stop
										v-on="on"
									>
									Privacy Policy
									</a>
								</template>
								Opens in new window
							</v-tooltip>
						</div>
					</template>
				</v-checkbox>
			</v-container>
		</v-form>

		<div class="d-flex justify-space-between mt-10">
			<v-btn
				class="text-none letter-spacing-0"
				style="margin-left: -16px;"
				color="primary"
				text
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: query })"
			>
				{{ $vuetify.lang.t('$vuetify.signup.request.action.signin') }}
			</v-btn>
			<v-btn
			  :disabled="$v.$invalid"
			  class="text-none"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.signup.request.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { required, email, sameAs, minLength, maxLength, alphaNum } from 'vuelidate/lib/validators';
import countryCodes from 'country-codes-list';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneNumber = function(value, vm) {
	var country = vm.country.phone[vm.country.selected];
	try {
		var number = phoneUtil.parse('+' + country.code + value, country.id);
	} catch(err) {
	  	return false;
	}

	return phoneUtil.isValidNumberForRegion(number, country.id);
}

export default {
	name: 'signup-request',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.signup.request.title', { brand: this.$whitelabel.getClient().name }),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	validations: {
		firstname: { required },
		lastname: { required },
		username: { required, minLength: minLength(5), maxLength: maxLength(100), alphaNum },
		email: { required, email, maxLength: maxLength(100) },
		phone: { required, minLength: minLength(6), maxLength: maxLength(20), isPhoneNumber },
		referral: { minLength: minLength(6), maxLength: maxLength(40), alphaNum },
		password: { required, minLength: minLength(8) },
		passconf: { required, sameAsPassword: sameAs('password') },
		terms: { required, checked: (val) => { return val; } },
    },
	data: () => ({
		provider: 'botika',
		token: 'botika',
		country: {
			phone: countryCodes.customArray({
				id: '{countryCode}',
				code: '{countryCallingCode}',
				text: '{countryNameEn}'
			}, { sortBy: 'text' }),
			selected: 103
		},
		show: {
			password: false,
			passconf: false
		},
		error: {
			firstname: null,
			lastname: null,
			username: null,
			email: null,
			phone: null,
			referral: null,
			password: null,
			passconf: null,
			terms: null,
		},
		loading: {
			username: false,
			email: false,
			phone: false,
			referral: false
		},
		disabled: {
			email: false
		},
		firstname: '',
		lastname: '',
		username: '',
		email: '',
		phone: '',
		referral: '',
		password: '',
		passconf: '',
		terms: false,
	}),
	computed: {
		firstnameError() {
			if ( ! this.$v.firstname.$dirty) {
				return null;
			} else if ( ! this.$v.firstname.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.firstname.error.required');
			} else if (this.error.firstname) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.firstname.error.'+this.error.firstname);
			}

			return this.error.firstname;
		},
		lastnameError() {
			if ( ! this.$v.lastname.$dirty) {
				return null;
			} else if ( ! this.$v.lastname.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.lastname.error.required');
			} else if (this.error.lastname) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.lastname.error.'+this.error.lastname);
			}

			return this.error.lastname;
		},
		usernameError() {
			if ( ! this.$v.username.$dirty) {
				return null;
			} else if ( ! this.$v.username.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.username.error.required');
			} else if ( ! this.$v.username.minLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.username.error.minlength');
			} else if ( ! this.$v.username.maxLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.username.error.maxlength');
			} else if ( ! this.$v.username.alphaNum) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.username.error.alphanum');
			} else if (this.error.username) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.username.error.'+this.error.username);
			}

			return this.error.username;
		},
		emailError() {
			if ( ! this.$v.email.$dirty) {
				return null;
			} else if ( ! this.$v.email.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.email.error.required');
			} else if ( ! this.$v.email.email) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.email.error.email');
			} else if ( ! this.$v.email.maxLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.email.error.maxlength');
			} else if (this.error.email) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.email.error.'+this.error.email);
			}

			return this.error.email;
		},
		phoneError() {
			if ( ! this.$v.phone.$dirty) {
				return null;
			} else if ( ! this.$v.phone.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.phone.error.required');
			} else if ( ! this.$v.phone.minLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.phone.error.minlength');
			} else if ( ! this.$v.phone.maxLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.phone.error.maxlength');
			} else if ( ! this.$v.phone.isPhoneNumber) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.phone.error.valid');
			} else if (this.error.phone) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.phone.error.'+this.error.phone);
			}

			return this.error.phone;
		},
		referralError() {
			if ( ! this.$v.referral.$dirty) {
				return null;
			} else if ( ! this.$v.referral.minLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.referral.error.minlength');
			} else if ( ! this.$v.referral.maxLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.referral.error.maxlength');
			} else if ( ! this.$v.referral.alphaNum) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.referral.error.alphanum');
			} else if (this.error.referral) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.referral.error.'+this.error.referral);
			}

			return this.error.referral;
		},
		passwordError() {
			if ( ! this.$v.password.$dirty) {
				return null;
			} else if ( ! this.$v.password.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.password.error.required');
			} else if ( ! this.$v.password.minLength) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.password.error.minlength');
			} else if (this.error.password) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.password.error.'+this.error.password);
			}

			return this.error.password;
		},
		passconfError() {
			if ( ! this.$v.passconf.$dirty) {
				return null;
			} else if ( ! this.$v.passconf.required) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.passconf.error.required');
			} else if ( ! this.$v.passconf.sameAsPassword) { 
				return this.$vuetify.lang.t('$vuetify.signup.request.form.passconf.error.confirm');
			} else if (this.error.passconf) {
				return this.$vuetify.lang.t('$vuetify.signup.request.form.passconf.error.'+this.error.passconf);
			}

			return this.error.passconf;
		},
		termsError() {
			if ( ! this.$v.terms.$dirty) {
				return null;
			} else if ( !this.$v.terms.required | !this.$v.terms.checked) { 
				return 'You must agree to continue!';
			}

			return this.error.terms;
		}
	},
	mounted() {
		this.$store.commit("auth/setIdentifier", null);

		if (this.query.provider == 'google') {
			this.$emit('progress', true);
			this.$store.dispatch("auth/google-identifier", { token: this.query.token }).then(response => {
				this.$emit('progress', false);
				this.provider = 'google';
				this.token = this.query.token;
				this.firstname = response.data.data.firstname;
				this.lastname = response.data.data.lastname;
				this.email = response.data.data.email;
				this.disabled.email = true;
				this.$store.dispatch('alert/set', {type: 'info', content: this.$vuetify.lang.t('$vuetify.signup.alert.verify')});
				this.$store.dispatch('alert/show');
			}).catch(error => {
				if (error.response && error.response.status == 401) {
					this.$router.push({ name: 'rejected-token', query: { continue: this.query.continue } });
				} else {
					this.$router.push({ name: 'rejected-404', query: { continue: this.query.continue } });
				}
			});
		} else if (this.query.provider == 'facebook') {
			this.$emit('progress', true);
			this.$store.dispatch("auth/facebook-identifier", { token: this.query.token }).then(response => {
				this.$emit('progress', false);
				this.provider = 'facebook';
				this.token = this.query.token;
				this.firstname = response.data.data.firstname;
				this.lastname = response.data.data.lastname;
				this.email = response.data.data.email;
				this.disabled.email = true;
				this.$store.dispatch('alert/set', {type: 'info', content: this.$vuetify.lang.t('$vuetify.signup.alert.verify')});
				this.$store.dispatch('alert/show');
			}).catch(error => {
				if (error.response && error.response.status == 401) {
					this.$router.push({ name: 'rejected-token', query: { continue: this.query.continue } });
				} else {
					this.$router.push({ name: 'rejected-404', query: { continue: this.query.continue } });
				}
			});
		} else {
			this.$emit('progress', false);
		}
	},
	methods: {
		async requestCheckUsername() {
			try {
				const data = await axios({
					method: 'post',
					url: '/api/signup/verify/username',
					data: JSON.stringify({ username: this.username })
				});

				return data;
			} catch (err) {
				return err;
			}
		},
		async checkUsername() {
			this.$v.username.$touch();
			if (this.$v.username.$invalid) {
				return;
			}

			this.error.username = null;
			this.loading.username = true;

			const data = await this.requestCheckUsername();

			if (data.status != 204) {
				if (data.response && data.response.status == 400) {
					this.error.username = 'unique';
				} else {
					this.error.username = 'server';
				}
			}

			this.loading.username = false;
		},
		checkEmail() {
			this.$v.email.$touch();
			if (this.$v.email.$invalid) {
				return;
			}

			this.error.email = null;
			this.loading.email = true;

			axios({
                method: 'post',
                url: '/api/signup/verify/email',
                data: JSON.stringify({ email: this.email })
            })
			.then(async response => {
				let newusername = this.email.substring(0, this.email.indexOf('@')).replace(/[\W_]+/g,"");
				if (!this.username || this.username.substring(0, newusername.length - 1) == newusername) {
					while (true) {
						// generate username from email or add random number 1-255 after username
						this.username = (this.username) ? newusername +  Math.floor((Math.random() * 255) + 1).toString(): newusername;
						var check = await this.requestCheckUsername();
						if (check.status == 204) break;
					}
				}
			})
			.catch(error => {
            	if (error.response && error.response.status == 400) {
	            	this.error.email = 'unique';
            	} else {
            		this.error.email = 'server';
            	}
            }).finally(() => {
            	this.loading.email = false;
            })
		},
		checkPhone() {
			this.$v.phone.$touch();
			if (this.$v.phone.$invalid) {
				return;
			}

			this.error.phone = null;
			this.loading.phone = true;

			axios({
                method: 'post',
                url: '/api/signup/verify/phone',
                data: JSON.stringify({ 
                	phone: phoneUtil.format(phoneUtil.parse(this.phone, this.country.phone[this.country.selected].id), PhoneNumberFormat.E164), 
                	phonecode: this.country.phone[this.country.selected].id 
                })
            }).catch(error => {
            	if (error.response && error.response.status == 400) {
	            	this.error.phone = 'unique';
            	} else {
            		this.error.phone = 'server';
            	}
            }).finally(() => {
            	this.loading.phone = false;
            })
		},
		checkReferral() {
			this.$v.referral.$touch();
			if (this.$v.referral.$invalid) {
				return;
			}

			this.error.referral = null;
			this.loading.referral = true;

			axios({
                method: 'post',
                url: '/api/signup/verify/referral',
                data: JSON.stringify({ referral: this.referral })
            }).catch(error => {
            	if (error.response && error.response.status == 404) {
	            	this.error.referral = 'notfound';
            	} else if (error.response && error.response.status == 401) {
	            	this.error.referral = 'unauthorized';
            	} else {
            		this.error.referral = 'server';
            	}
            }).finally(() => {
            	this.loading.referral = false;
            })
		},
		next() {
			this.$v.$touch();
			if (
				this.$v.$invalid ||
				this.error.firstname ||
				this.error.lastname || 
				this.error.username || 
				this.error.email || 
				this.error.phone || 
				this.error.referral ||
				this.error.password ||
				this.error.passconf ||
				this.error.terms
			) {
				return;
			}

			this.error.firstname = null;
			this.error.lastname = null;
			this.error.username = null;
			this.error.email = null;
			this.error.phone = null;
			this.error.referral = null;
			this.error.password = null;
			this.error.passconf = null;
			this.error.terms = null;
			this.$emit('progress', true);

			this.$store.dispatch("auth/signup", {
				provider: this.provider,
				token: this.token,
				firstname: this.firstname,
                lastname: this.lastname,
                username: this.username,
                email: this.email,
                phonecode: this.country.phone[this.country.selected].id,
                phone: phoneUtil.format(phoneUtil.parse(this.phone, this.country.phone[this.country.selected].id), PhoneNumberFormat.E164),
                password: this.password,
                passconf: this.passconf,
                referral: this.referral,
				terms: this.terms,
			}).then(() => {
				this.$store.dispatch('alert/hide');
				this.$emit('progress', true);
				this.$router.push({ name: 'signin-identifier', query: this.query });
			}).catch(() => {
				this.$emit('progress', false);
				this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.error.server')});
				this.$store.dispatch('alert/show');
			});
		}
	}
}
</script>