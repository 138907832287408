<style lang="scss">
.transition-wrapper{
	position: relative;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active {
	transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
	position: absolute;
	width: 100%;
}

.slide-left-enter, .slide-right-leave-to {
	transform: translateX(500px);
}

.slide-left-leave-to, .slide-right-enter {
	transform: translateX(-500px);
}
</style>
<template>
	<div>
	    <v-card
	      light
	      :disabled="progress"
	      :loading="progress"
	      :style="style"
	    >
	    	<div class="pa-10">
	    		<v-container class="pa-0">
					<v-row
						align="center"
						justify="space-between"
					>
						<v-col
							:cols="($vuetify.breakpoint.smAndDown ? 12 : 7)"
						>
							<v-img
								class="mb-5"
					    	  	:class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
								:alt="`${$whitelabel.getName()} Logo`"
								width="120"
								style="vertical-align: middle;"
							>
								<img :src="`${$whitelabel.getPublicPath()}img/logo.png`" style="width: 100%; height: 100%;" />
							</v-img>
							<div class="transition-wrapper">
								<transition :name="transitionName">
							        <router-view @progress="setProgress" />
								</transition>
							</div>
						</v-col>

						<v-col
						  v-if="!$vuetify.breakpoint.smAndDown"
						  cols="4"
						  class="text--secondary"
						>
							<img
							  :src="`${$whitelabel.getPublicPath()}img/signup.png`"
							  :alt="`${$whitelabel.getName()} Sign Up Logo`"
							  style="width: 100%;height: auto;"
							>
							{{ $vuetify.lang.t('$vuetify.signup.caption', { brand: $whitelabel.getClient().name }) }}
						</v-col>
					</v-row>
				</v-container>
			</div>
	    </v-card>

	    <Footer />
	</div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
    name: "SignUp",
    components: {
    	Footer
    },
    data: () => ({
        progress: true,
        transitionName: 'slide-left'
    }),
    computed: {
    	style() {
    		if ( ! this.$vuetify.breakpoint.smAndDown) {
    			return 'width:750px;min-height: 500px;overflow: hidden;max-width:750px;';
    		}

    		return '';
    	}
    },
    watch: {
		$route (to, from) {
			if (from.name == 'signup-request' && to.name == 'signup-success') {
				this.transitionName = 'slide-left';
			} else {
				this.transitionName = 'slide-right';
			}
		}
	},
	mounted() {
		this.$emit('progress', false);
	},
    methods: {
        setProgress (progress) {
            this.progress = progress;
        }
    }
}
</script>